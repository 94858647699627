import redline from "./05-06-23.png";
import athentic from "./05-13-23.png";
// @ts-ignore
import cozyBar from "./04-12-23.JPG";
import worldFamous from "./5-28-23.png";
// @ts-ignore
import bluePizza from "./07-21-23.JPG";
// @ts-ignore
import worldFamousTwo from "./07-13-23.JPG";
// @ts-ignore
import queerProm from "./09-29-23.JPG";
import buvez from "./10-14-23.png";
import cine from "./12-08-23.png";
import flicker from "./01-06-24.png";
import munsoon from "./01-27-24.png";
import flickerTwo from "./02-23-24.png";
import redlineTwo from "./05-17-24.png";
import buvezTwo from "./07-09-24.png";
import gyro from "./06-08-24.png";

export const SHOWS = [
  {
    title: "Folk, Country, & More",
    date: "Wednesday, 04/12/23",
    dateProper: new Date("2023-04-13"),
    description:
      "viv awesome, Cowboy Kerouac, Henry Luther (SC Americana). 8PM/Donate what you can for gas",
    location: "Cozy Bar: 179 N Jackson St, Athens, GA",
    flyer: cozyBar,
  },
  {
    title: "Country/Folk Punk Night",
    date: "Saturday, 05/06/23",
    dateProper: new Date("2023-05-07"),
    description:
      "Yankee Roses, Here Be Monsters, Gift Economy, Cowboy Kerouac. 7PM/$5",
    location: "Redline Athens: 1958 Winterville Rd, Athens, GA",
    flyer: redline,
  },
  {
    title: "Mutual Aid Athens Spring 2023 Fundraiser",
    date: "Saturday, 05/13/23",
    dateProper: new Date("2023-05-14"),
    description:
      "4-10 PM / $5-10 Suggested Donation. Come gather for Mutual Aid Athens’ Spring Fundraiser, filled with art, community building, local music, and delicious beer! MAA is a local, non-hierarchical organization focused on providing direct aid to our community members most in need. We assist our unhoused neighbors, as well as housed neighbors facing serious economic challenges, by providing them with monetary assistance, food, resources, and anything we’re able to pool our resources together for based on their needs. Our members feed our unhoused Athens neighbors every single day! Music: Patrick Barry, Cowboy Kerouac, Deaf Condors, Newport Transplant",
    location: "Athentic Brewing: 108 Park Ave, Athens, GA",
    flyer: athentic,
  },
  {
    title: "TACK, Instant Smile, Cowboy Kerouac @ World Famous",
    date: "Sunday, 05/28/23",
    dateProper: new Date("2023-05-29"),
    description:
      "8:30 PM, $5 suggested donation. TACK (New Orleans garage cowpunk), Instant Smile (Athens heavy pop duo), & Cowboy Kerouac",
    location: "The World Famous: 351 N Hull St, Athens, GA",
    flyer: worldFamous,
  },
  {
    title: "DSA Fundraiser @ World Famous",
    date: "Thursday, 07/13/23",
    dateProper: new Date("2023-07-13"),
    description:
      "6 PM. Beat Up, Cowboy Kerouac, & Liz Farrell. Donations highly suggested!",
    location: "The World Famous: 351 N Hull St, Athens, GA",
    flyer: worldFamousTwo,
  },
  {
    title: "Cowboy Kerouac & The Cocaine Fairies @ Blue Pizza, Columbia, SC",
    date: "Friday, 07/21/23",
    dateProper: new Date("2023-07-21"),
    description:
      "6 PM, $10. Cowboy Kerouac & The Cocaine Fairies, Henry Luther, Bombadier, & Ramblin' Road",
    location: "Blue Pizza: 2123B Greene St, Columbia, SC 29205",
    flyer: bluePizza,
  },
  {
    title: "Athens Pride & Queer Collective presents: Queer Prom",
    date: "Friday, 09/29/23",
    dateProper: new Date("2023-09-29"),
    description: "7 PM, $5, 21+. w/ DJ De La Luna",
    location: "1055 Barber St, Athens, GA",
    flyer: queerProm,
  },
  {
    title: "Anders Thompsen Trio, Yankee Roses, & Cowboy Kerouac @ Buvez",
    date: "Saturday, 10/14/23",
    dateProper: new Date("2023-10-14"),
    description:
      "7 PM, $10. Anders Thompsen Trio (Savannah classic country), Yankee Roses (ATL Folk Punk), Cowboy Kerouac & The Barebacks",
    location: "Buvez: 585 Barber St, Athens, GA",
    flyer: buvez,
  },
  {
    title:
      "Buck n Stuff, Straightjacket Strippers, Deaf Condors, Cowboy Kerouac & The Barebacks",
    date: "Friday, 12/8/23",
    dateProper: new Date("2023-12-08"),
    description:
      "9 PM, $10. Buck n Stuff (Nashville weirdo rock country), Straightjacket Strippers (ATH sludge punk), Deaf Condors (ATH noisy Garage Rock Duo), Cowboy Kerouac & The Barebacks",
    location: "Cine: 234 W Hancock Ave, Athens, GA 30601",
    flyer: cine,
  },
  {
    title:
      "Henry Luther & the Cocaine Fairies, Cowboy Kerouac & The Barebacks, Here Be Monsters",
    date: "Saturday, 1/6/24",
    dateProper: new Date("2024-01-06"),
    description:
      "Henry Luther & the Cocaine Fairies (Columbia, SC country), Here Be Monsters (ATH folk punk), Cowboy Kerouac & The Barebacks",
    location: "Flicker: 263 W Washington St, Athens, GA 30601",
    flyer: flicker,
  },
  {
    title: "Cowboy Kerouac & The Barebacks, Catch These Hands, Grounds Crew",
    date: "Saturday, 1/27/24",
    dateProper: new Date("2024-01-27"),
    description:
      "Cowboy Kerouac & The Barebacks, Catch These Hands (ATL Skate Punk), Grounds Crew (ATL Hardcore Punk)",
    location: "Munsoon House (ATL): 1521 Waltham Pl S4, Atlanta, GA",
    flyer: munsoon,
  },
  {
    title: "The Muckers, Mercyland, Beat Up, Cowboy Kerouac & The Barebacks",
    date: "Friday, 2/23/24",
    dateProper: new Date("2024-02-23"),
    description:
      "The Muckers (ATL Celtic Folk Rock), Mercyland (Legendary ATH punk), Beat Up (ATH Agitprop Punk), Cowboy Kerouac & The Barebacks (CHAOS COUNTRY Album Release Show!)",
    location: "Flicker: 263 W Washington St, Athens, GA 30601",
    flyer: flickerTwo,
  },
  {
    title: "Gebidan, The Chodes, Beat Up, Cowboy Kerouac",
    date: "Friday, 5/17/24",
    dateProper: new Date("2024-05-17"),
    description:
      "7 PM, $5. The Chodes (FL Punk), Gebidan (ATH Post Punk) Beat Up (ATH Agitprop Punk), Cowboy Kerouac",
    location: "Redline Athens: 1958 Winterville Rd, Athens, GA",
    flyer: redlineTwo,
  },
  {
    title: "Magnetic Health Theater, Chairs, Cowboy Kerouac, Simon Hanes",
    date: "Saturday, 6/8/24",
    dateProper: new Date("2024-08-08"),
    description:
      "7 PM, $5. Magnetic Health Theater (MA Folk), Chairs (ATH progressive noise/ambient), Simon Hanes (ATH Folk of Trdici Bacci), Cowboy Kerouac",
    location: "Buvez: 585 Barber St, Athens, GA",
    flyer: gyro,
  },
  {
    title:
      "69 Dudes, Henry Luther and The Blackouts, Cowboy Kerouac & The Barebacks",
    date: "Tuesday, 7/9/24",
    dateProper: new Date("2024-07-09"),
    description:
      "7 PM, $10. 69 Dudes (ATH radass punk), Henry Luther and The Blackouts (SC Chaos Folk), Cowboy Kerouac & The Barebacks",
    location: "Buvez: 585 Barber St, Athens, GA",
    flyer: buvezTwo,
  },
];
