import React, { useState } from "react";
import "./App.css";
import { SHOWS } from "./shows";
import cklogo from "./cklogo.png";
import { Modal } from "./Modal";

function App() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="App">
      {modalOpen && <Modal closeModal={() => setModalOpen(false)} />}
      <header>
        <img src={cklogo} />
        <p>
          <a href="https://linktr.ee/cowboykerouac">Streaming/Socials</a>{" "}
          <a href="mailto:cowboy.kerouac69@gmail.com">Booking</a>
          <a href="#" onClick={() => setModalOpen(true)}>
            About
          </a>
        </p>
        <p>
          "Surely this is mana from heaven for those of you who've felt that the
          blasphemy quotient of punk rock has really been tamped down... Makes
          that Vandals country album sound like merely cheeky R.E.M. ... A
          masterpiece, more or less." - <span>Rev. Nørb, Razorcake</span>
        </p>
        <p>
          "Chairman Mao-level murder-as-retribution wish of... 'every
          billionaire is dead.' So, all you billionaires out there better watch
          out, because here comes Cowboy Kerouac" -{" "}
          <span>Gordon Lamb, Flagpole Magazine</span>
        </p>
        <p>
          "Unhinged" - <span>Athens Politics Nerd</span>
        </p>
      </header>
      <div className="body">
        <h1>UPCOMING SHOWS</h1>
        {SHOWS.filter((show) => {
          return show.dateProper > new Date();
        }).map((show) => {
          return (
            <div key={show.date} className="show">
              <h2>
                {show.date} - {show.title}
              </h2>
              <div className="flex">
                <div className="img">
                  {show.flyer && <img src={show.flyer} />}
                </div>
                <div className="desc">
                  <p>
                    <b>@ {show.location}</b> - {show.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
        {!SHOWS.filter((show) => {
          return show.dateProper > new Date();
        }).length && (
          <div className="show">
            <h2>No upcoming shows.</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
