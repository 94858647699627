import React from "react";
import "./Modal.css";
// @ts-ignore
import dildo from "./dildo.JPG";

export const Modal = (props: any) => {
  const { closeModal } = props;

  return (
    <div className="modal">
      <h1>About</h1>
      <img src={dildo} />
      <p>
        Hailing from Athens, GA, Cowboy Kerouac combines the sounds of classic
        country, folk, & punk with queer & leftist lyrics aimed at communities
        that mainstream country music has long since left behind. The liberation
        of and solidarity within intersectional marginalized groups- working
        class and queer folks in particular- is highlighted through biting
        satire, excessive profanity, and the silliest of celebrations of the
        best parts of our community.
      </p>
      <p>
        Chicken-fried & queer as fuck, these songs ain't for the faint of heart.
      </p>
      <h2>RELEASES</h2>
      <h3>Available on all streaming services!</h3>
      <ul>
        <li>
          <span className="italic">Somehow I Survived</span>, 2024
        </li>
        <li>
          <span className="italic">Chaos Country (LP)</span>, 2024
        </li>
        <li>
          <span className="italic">
            We're Gonna Need a Bigger Submarine (Beat Up Split)
          </span>
          , 2023
        </li>
        <li>
          <span className="italic">Honky-Tonk Hellscape EP</span>, 2023
        </li>
        <li>
          <span className="italic">Let's Get Canceled Together (LP)</span>, 2022
        </li>
      </ul>
      <button onClick={closeModal}>Close</button>
    </div>
  );
};
